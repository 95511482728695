import React from 'react'
import { RecentExprience,  ImageContainer } from './ExprienceElements';
import WebsiteData from "../../data/data.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function exprience() {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return (
        <>

      
            <RecentExprience >
                    Experience Timeline
                </RecentExprience>
               
       

       <Carousel 
        swipeable={false}
        draggable={false}
        showDots={false}
        ssr={true}
        keyBoardControl={true}
        itemClass="carousel-item-padding-40-px"
       responsive={responsive} >

       {

              WebsiteData.experiences.map( (experience) =>
              
              <ImageContainer>

           
              <nav id="title">
              {experience.title}
                      </nav>
                      <nav id="date">
              {experience.date}
                      </nav>    
                     
                      <img id="experienceImage" src={require('../../data/'+experience.imagePath)} alt="" />
                    
                      <p id="descriptionOfExperience">
                      {experience.description}
                   </p>
       
                   </ImageContainer>
                  ) 
              }
            </Carousel>
       
   
        
        </>
    )
}

export default exprience
