

var WebsiteData = { headerNames : [ {name : "About"}, {name : "Experience" }, {name : "Resume"}, {name : "Contact" }],
intro : {name  : "Fayed Raza", intros : ["Senior @ Rutgers University", "Learning Assistant for Rutgers CS112 Data Structures", "Hi, my name is Fayed. I am currently a student at Rutgers University, majoring in Computer Science and minoring in Mathematics. Apart from school, being a learning assistant, and interning, I like to build apps. Whether it is a bot, website, etc., I have an interest in learning and building it, especially when I get to work with others."], imagePath : "profileImage.jpeg"},
socialMediaLinks : [{name :  "Github", link : "https://github.com/fayedraza/"},{name :  "Linkedln", link : "https://www.linkedin.com/in/fayed-raza/"} , {name : "Instagram", link : "https://www.instagram.com/faderazor/"}],
experiences : [ {title : "Rutgers University", date : "September 2019" , description : "I joined Rutgers University in the Fall of 2019.", imagePath : "images/rutgers.png"},
{title : "Twitter Bot", date : "July 2020" , description : "I built a Twitter Bot that Tweets Daily Deals from Amazon using the Twitter API. I built the bot using the language, Python. In addition, I used web scraping (Beautiful Soup) to grab the elements from Amazon such as the rating, new price, original price, etc.", imagePath : "images/twitter.png"},  
{title : "Online Teaching Tool",  date : "August 2020" , description : "I built a web app to use as a tool for online classes where teachers can make virtual classes feel more interactive by adding a feature to give quizzes to students and a chatbot for students to ask questions using HTML, CSS, and Javascript.", imagePath : "images/education.png"},
{title : "Weather Chrome Extension", date : "January 2021" , description : "I built a Weather Chrome Extension using the Open Weather API to get the weather according to the location input. In addition, I implemented the back-end in NodeJS and used the Twilio API to send text messages about weather-related activities.", imagePath : "images/sun.png"},
{title : "Data Structures Learning Assistant", date : "January 2021" , description : "I led a recitation of 15-20 students per week and covered concepts about data structures and their implementation. In addition, I prepared lessons before teaching. Finally, I made sure that students attended recitation and understood the lesson through group discussions and quizzes.", imagePath : "images/teaching.png"},
{title : "Vanguard", date : "June 2021" , description : "During Summer of 2021, I interned at Vanguard where I worked with the CX PAS Onboarding team to make the pages more user-friendly for our clients who want to join Vanguard using Angular. In addition, I worked with another team to make a desktop app for workers at Vanguard to view and attend volunteer events using Electron and AWS DynamoDB.", imagePath : "images/vanguard.png"},
{title : "Chess Game Android App", date : "May 2022" , description : "In my Software Methodology class, my project partner and I developed an Android app that allows two users to play chess using Java. I implemented the ability to show all possible squares to land a chess piece on after clicking a piece.", imagePath : "images/chess.png"},
{title : "Capital One", date : "June 2022" , description : "During the Summer of 2022, I interned at Capital One, where I worked with the Card Data team to build an application that automates and speeds up the process of submitting SQL transformations, approving those submissions, and generating the YAML and SQL files needed once approved. I built a dashboard that allows users to track the status of their submissions and view them using React and AWS RDS. In addition, I constructed an API to automate the process of creating pull requests to a repo after YAML and SQL files are generated using FastAPI and the GitHub API.", imagePath : "images/capitalone.png"}

]


};

export default WebsiteData;