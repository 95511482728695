import React from 'react';
import './App.css';
import Page from './wholepage/index';



function App() {
  return (
  
<>
<Page/>
 
  </>
  );
}

export default App;
