import React , { useState }from 'react';
import Navbar from '../components/Navbar/TopHeader';
import Sidebar from '../components/Sidebar/Sidebar';
import Introduction from '../components/Introduction/Introduction';
import Experience from '../components/Experience/Experience';
import Contact from '../components/Contact/Contact';
import {
  BrowserRouter as Router
} from 'react-router-dom';


const Page = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

  return (
  <>
 <Router>
      <Sidebar isOpen = {isOpen} toggle = {toggle}/>
       <Navbar toggle = {toggle}/>
       
       <Introduction/>
       <Experience/>
      { /*<Contact/>*/}
       
  </Router>  
     
  </>
  );
}

export default Page;