import React, {Component} from 'react';
import {isMobile} from 'react-device-detect';
import Video from '../../data/coding.mp4';
import {IntroContainer, IntroVideo, Intro, VideoBg, Content, Name, ProfilePic} from './IntroductionElements'
import WebsiteData from "../../data/data.js";
import ProfileImage from '../../data/images/profileImage.jpeg';
class intro extends Component {

 
    render() {

        const backgroundGif = () => {

            if(!isMobile){
                return <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>;
            }
    
        }
        
    return (
       
      

        <IntroContainer>
              
             <IntroVideo>

               {backgroundGif()}

               </IntroVideo>

        <Content>  
            <Name>
            {WebsiteData.intro.name}
            </Name>

            {
              WebsiteData.intro.intros.map( (intros) =>
              <Intro>
             {intros}
          </Intro>
                  ) 
              }

             <ProfilePic>
               <img id="profilePic" src={ProfileImage} alt="nice" />
            </ProfilePic>
         
        </Content>

    

           
           
     </IntroContainer>
    );
    }
};

export default intro;