import styled from 'styled-components'

export const RecentExprience  = styled.nav`
color: #B0E0E6;
font-size: 2.5rem;
text-align: center;
padding-top: 1%;
@media only screen and (min-width: 781px) {
  
}
@media only screen and (max-width: 780px) {
   
    
  
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
  
}
@media only screen and (max-width: 1100px) {
    margin: 5px;
    align-content: center;
   
  
}
@media only screen and (max-width: 1100px) and (orientation: portrait) {
   
   
    margin: 5px;
    align-content: center;
  
}
@media only screen and (max-width: 1100px) and (orientation: landscape) {
   
   
    margin: 5px;
    align-content: center;
  
}
`

export const ImageContainer = styled.div`
float: center;
`

export const titleofexprience  = styled.nav`
    position: absolute;
    bottom: 0px;
    color: #B0E0E6;
    @media only screen and (max-width: 1100px) {
   
        display: block;
    
        float: initial;
    
        margin:0;
        margin-bottom: 5%;
    justify-content: center;
    
    margin-right:auto;
    margin-left:auto;
    
      
    }
    
    @media only screen and (max-width: 1100px) and (orientation: portrait) {
          
        display: block;
    
        float: initial;
    
        margin:0;
        margin-bottom: 5%;
    justify-content: center;
    
    margin-right:auto;
    margin-left:auto;
    
    
    }
    @media only screen and (max-width: 1100px) and (orientation: landscape) {
          
        display: block;
    
        float: initial;
    
        margin:0;
        margin-bottom: 5%;
    justify-content: center;
    
    margin-right:auto;
    margin-left:auto;
    
    
    }
    
`
export const discriptionofexprience  = styled.a`
    margin-top: 1%;
  font-size: 5rem;
  color: #B0E0E6;
 
  text-align: left;
    @media only screen and (min-width: 781px) {
  
    }
    
    @media only screen and (max-width: 780px) {
       
    
   
    }
    
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      
    }
    @media only screen and (max-width: 1100px) {
   
        display: block;
    
        float: initial;
    
        margin:0;
        
        margin-bottom: 5%;
    justify-content: center;
    
    margin-right:auto;
    margin-left:auto;
    
        
    
    }
    
    @media only screen and (max-width: 1100px) and (orientation: portrait) {
          
       
        display: block;
    
        float: initial;
    
        margin:0;
        margin-bottom: 5%;
    justify-content: center;
    
    margin-right:auto;
    margin-left:auto;
       
   
    }
    @media only screen and (max-width: 1100px) and (orientation: landscape) {
          
       
        display: block;
    
        float: initial;
    
        margin:0;
        margin-bottom: 5%;
    justify-content: center;
    
    margin-right:auto;
    margin-left:auto;
       
   
    }
`